const URLSBases = {
    apisTc: 'https://apis.ayude.com.br',
    wsTc: 'ws://apis.ayude.com.br',
    // apisTc: 'http://localhost:3333',
    // wsTc: 'ws://localhost:3333',
    dataApi: 'https://api-data.ayude.com.br',
    authApi: 'https://auth.ayude.com.br/v1',
    LOGIN: 'http://login.ayude.com.br'  + "?acesso=" + window.location.hostname.split('.')[0],
    utils: 'https://utils.ayude.com.br'
    // utils: 'http://localhost:3333'

}

export default URLSBases;