export const sisErrors = [
    {
        "key": "SIS0001",
        "value": "Erro genérico. Entre em contato com a equipe de suporte Entrepay"
    },
    {
        "key": "SIS0002",
        "value": "Erro genérico. Entre em contato com a equipe de suporte Entrepay"
    },
    {
        "key": "SIS0003",
        "value": "Erro genérico. Entre em contato com a equipe de suporte Entrepay"
    },
    {
        "key": "SIS0004",
        "value": "Erro genérico. Entre em contato com a equipe de suporte Entrepay"
    },
    {
        "key": "SIS0005",
        "value": "Erro genérico. Entre em contato com a equipe de suporte Entrepay"
    },
    {
        "key": "SIS0006",
        "value": "Erro genérico. Entre em contato com a equipe de suporte Entrepay"
    },
    {
        "key": "SIS0007",
        "value": "A mensagem enviada pelo comerciante não está correta"
    },
    {
        "key": "SIS0008",
        "value": "Ds_Merchant_MerchantCode ausente"
    },
    {
        "key": "SIS0754",
        "value": "O banco recusou o pagamento, verifique os dados do cartão e tente novamente"
    },
    {
        "key": "SIS0009",
        "value": "Erro de formato em Ds_Merchant_MerchantCode"
    },
    {
        "key": "SIS0010",
        "value": "Ds_Merchant_Terminal ausente"
    },
    {
        "key": "SIS0011",
        "value": "Erro de formato em Ds_Merchant_Terminal"
    },
    {
        "key": "SIS0012",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0013",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0014",
        "value": "Erro de formato em Ds_Merchant_Order"
    },
    {
        "key": "SIS0015",
        "value": "Ds_Merchant_Currency ausente"
    },
    {
        "key": "SIS0016",
        "value": "Erro de formato em Ds_Merchant_Currency"
    },
    {
        "key": "SIS0018",
        "value": "Ds_Merchant_Amount ausente"
    },
    {
        "key": "SIS0019",
        "value": "Ds_Merchant_Amount ausente"
    },
    {
        "key": "SIS0020",
        "value": "Ds_Merchant_MerchantSignature ausente"
    },
    {
        "key": "SIS0021",
        "value": "Ds_Merchant_MerchantSignature vem vazio"
    },
    {
        "key": "SIS0022",
        "value": "Erro de formato em Ds_Merchant_TransactionType"
    },
    {
        "key": "SIS0023",
        "value": "Ds_Merchant_TransactionType desconhecido"
    },
    {
        "key": "SIS0024",
        "value": "Erro Ds_Merchant_ConsumerLanguage tem mais de 3 posições"
    },
    {
        "key": "SIS0025",
        "value": "Erro de formato em Ds_Merchant_ConsumerLanguage"
    },
    {
        "key": "SIS0026",
        "value": "O comerciante / terminal enviado não existe"
    },
    {
        "key": "SIS0027",
        "value": "Moeda do erro enviada pelo comerciante"
    },
    {
        "key": "SIS0028",
        "value": "Comércio / terminal desativado"
    },
    {
        "key": "SIS0029",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0030",
        "value": "tipo de operação errada"
    },
    {
        "key": "SIS0031",
        "value": "método de pagamento errado"
    },
    {
        "key": "SIS0032",
        "value": "Falha ao montar mensagem para uma devolução"
    },
    {
        "key": "SIS0033",
        "value": "tipo de operação está errado"
    },
    {
        "key": "SIS0034",
        "value": "Erro de acesso ao banco de dados"
    },
    {
        "key": "SIS0035",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0037",
        "value": "O número de telefone não é válido"
    },
    {
        "key": "SIS0038",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0039",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0040",
        "value": "O comerciante / terminal não tem nenhum método de pagamento atribuído"
    },
    {
        "key": "SIS0041",
        "value": "Erro de cálculo de assinatura"
    },
    {
        "key": "SIS0042",
        "value": "Erro de cálculo de assinatura"
    },
    {
        "key": "SIS0043",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0044",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0046",
        "value": "O compartimento do cartão não está registrado no FINANET"
    },
    {
        "key": "SIS0047",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0048",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0049",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0050",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0051",
        "value": "Número do pedido repetido"
    },
    {
        "key": "SIS0052",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0053",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0054",
        "value": "Não há operação para fazer a devolução"
    },
    {
        "key": "SIS0055",
        "value": "há mais de um pagamento com o mesmo número de pedido"
    },
    {
        "key": "SIS0056",
        "value": "A operação sobre a qual você deseja retornar não está autorizada"
    },
    {
        "key": "SIS0057",
        "value": "O valor a ser devolvido excede o permitido"
    },
    {
        "key": "SIS0058",
        "value": "Os dados de validação estão errados"
    },
    {
        "key": "SIS0059",
        "value": "Não há operação para fazer a confirmação"
    },
    {
        "key": "SIS0060",
        "value": "Já existe uma confirmação associada à pré-autorização"
    },
    {
        "key": "SIS0061",
        "value": "A pré-autorização sobre a qual você deseja confirmar não está autorizada"
    },
    {
        "key": "SIS0062",
        "value": "O valor a ser confirmado excede o permitido"
    },
    {
        "key": "SIS0063",
        "value": "Número de cartão inválido."
    },
    {
        "key": "SIS0064",
        "value": "Número incorreto de posições de cartão"
    },
    {
        "key": "SIS0065",
        "value": "O número do cartão não é numérico"
    },
    {
        "key": "SIS0066",
        "value": "Erro de mês de expiração"
    },
    {
        "key": "SIS0067",
        "value": "O mês de expiração não é numérico"
    },
    {
        "key": "SIS0068",
        "value": "O mês de vencimento não é válido"
    },
    {
        "key": "SIS0069",
        "value": "Ano de expiração inválido"
    },
    {
        "key": "SIS0070",
        "value": "O ano de expiração não é numérico"
    },
    {
        "key": "SIS0071",
        "value": "cartão expirado"
    },
    {
        "key": "SIS0072",
        "value": "Operação não anulável"
    },
    {
        "key": "SIS0073",
        "value": "Erro de cancelamento"
    },
    {
        "key": "SIS0074",
        "value": "Ds_Merchant_Order ausente (pedido)"
    },
    {
        "key": "SIS0075",
        "value": "O \"Ds_Merchant_Order\" tem menos de 4 ou mais de 12 posições."
    },
    {
        "key": "SIS0077",
        "value": "Erro de formato em Ds_Merchant_Order"
    },
    {
        "key": "SIS0078",
        "value": "Tipo de operação não permitido para esse cartão"
    },
    {
        "key": "SIS0079",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0080",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0081",
        "value": "Os dados da sessão foram perdidos"
    },
    {
        "key": "SIS0082",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0083",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0084",
        "value": "O valor de Ds_Merchant_Conciliation é nulo"
    },
    {
        "key": "SIS0085",
        "value": "O valor de Ds_Merchant_Conciliation não é numérico"
    },
    {
        "key": "SIS0086",
        "value": "O valor de Ds_Merchant_Conciliation não ocupa 6 posições"
    },
    {
        "key": "SIS0087",
        "value": "O valor de Ds_Merchant_Session é nulo"
    },
    {
        "key": "SIS0088",
        "value": "O valor de Ds_Merchant_Session não é numérico"
    },
    {
        "key": "SIS0089",
        "value": "O valor de vencimento não ocupa 4 posições"
    },
    {
        "key": "SIS0090",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0091",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0092",
        "value": "O valor de expiração é nulo"
    },
    {
        "key": "SIS0093",
        "value": "Negação do emissor"
    },
    {
        "key": "SIS0094",
        "value": "Negação do emissor"
    },
    {
        "key": "SIS0095",
        "value": "Negação do emissor"
    },
    {
        "key": "SIS0097",
        "value": "Valor do campo Ds_Merchant_CComercio inválido"
    },
    {
        "key": "SIS0098",
        "value": "Valor do campo Ds_Merchant_CVentana inválido"
    },
    {
        "key": "SIS0099",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0103",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0104",
        "value": "Negocie com \"proprietário seguro\" e proprietário sem chave de compra segura"
    },
    {
        "key": "SIS0112",
        "value": "O tipo de transação especificado em Ds_Merchant_Transaction_Type não é permitido"
    },
    {
        "key": "SIS0113",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0114",
        "value": "A chamada está sendo feita por GET, tem que ser feita por POST"
    },
    {
        "key": "SIS0115",
        "value": "Não há operação para pagar a taxa"
    },
    {
        "key": "SIS0116",
        "value": "A operação sobre a qual você deseja pagar uma taxa não é uma operação válida"
    },
    {
        "key": "SIS0117",
        "value": "A operação sobre a qual você deseja pagar uma taxa não está autorizada"
    },
    {
        "key": "SIS0118",
        "value": "O valor total das parcelas foi ultrapassado"
    },
    {
        "key": "SIS0119",
        "value": "Valor do campo Ds_Merchant_DateFrecuency inválido"
    },
    {
        "key": "SIS0120",
        "value": "Valor do campo Ds_Merchant_ChargeExpiryDate inválido"
    },
    {
        "key": "SIS0121",
        "value": "Valor do campo Ds_Merchant_SumTotal inválido"
    },
    {
        "key": "SIS0122",
        "value": "Formato incorreto do campo Ds_Merchant_DateFrecuency ou Ds_Merchant_SumTotal"
    },
    {
        "key": "SIS0123",
        "value": "O prazo para realização da Transação foi ultrapassado"
    },
    {
        "key": "SIS0124",
        "value": "A frequência mínima não decorreu em um pagamento recorrente sucessivo"
    },
    {
        "key": "SIS0125",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0126",
        "value": "Operação duplicada"
    },
    {
        "key": "SIS0127",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0128",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0130",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0131",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0132",
        "value": "A data de confirmação de autorização não pode exceder a data de pré-autorização em mais de 7 dias."
    },
    {
        "key": "SIS0133",
        "value": "A data de confirmação de autenticação não pode exceder a data de autenticação anterior em mais de 45 dias."
    },
    {
        "key": "SIS0134",
        "value": "O valor dos Ds_MerchantCiers enviados não é válido"
    },
    {
        "key": "SIS0139",
        "value": "O pagamento recorrente inicial é duplicado"
    },
    {
        "key": "SIS0140",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0142",
        "value": "Tempo excedido para pagamento"
    },
    {
        "key": "SIS0151",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0169",
        "value": "Valor PUCE Ds_Merchant_MatchingData inválido"
    },
    {
        "key": "SIS0170",
        "value": "Valor PUCE Ds_Acquirer_Identifier inválido"
    },
    {
        "key": "SIS0171",
        "value": "Valor PUCE Ds_Merchant_Csb inválido"
    },
    {
        "key": "SIS0172",
        "value": "O valor do campo PUCE Ds_Merchant_MerchantCode não é válido"
    },
    {
        "key": "SIS0173",
        "value": "Valor do campo PUCE Ds_Merchant_UrlOK inválido"
    },
    {
        "key": "SIS0174",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0175",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0181",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0182",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0183",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0184",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0186",
        "value": "Dados ausentes para operação"
    },
    {
        "key": "SIS0187",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0197",
        "value": "Erro ao obter os dados do carrinho de compras"
    },
    {
        "key": "SIS0214",
        "value": "O comércio não permite devoluções. O uso de assinatura estendida é obrigatório."
    },
    {
        "key": "SIS0216",
        "value": "O CVV2 tem mais de 3 posições"
    },
    {
        "key": "SIS0217",
        "value": "Erro de formato em CVV2"
    },
    {
        "key": "SIS0218",
        "value": "A troca permite apenas operações seguras e uma operação INSEGURA está sendo enviada por entradas de Host para Host"
    },
    {
        "key": "SIS0219",
        "value": "O número de operações com cartão excede o limite permitido para o comerciante"
    },
    {
        "key": "SIS0220",
        "value": "O valor acumulado do cartão excede o limite permitido para o comerciante"
    },
    {
        "key": "SIS0221",
        "value": "CVV2 é obrigatório"
    },
    {
        "key": "SIS0222",
        "value": "Já existe uma substituição associada à pré-autorização"
    },
    {
        "key": "SIS0223",
        "value": "A pré-autorização a ser cancelada não está autorizada"
    },
    {
        "key": "SIS0224",
        "value": "A loja não permite cancelamentos por não possuir assinatura estendida"
    },
    {
        "key": "SIS0225",
        "value": "Não há operação para cancelar"
    },
    {
        "key": "SIS0226",
        "value": "Erro de dados de cancelamento"
    },
    {
        "key": "SIS0227",
        "value": "Valor do campo Ds_Merchant_TransactionDate inválido"
    },
    {
        "key": "SIS0228",
        "value": "Você só pode fazer pagamentos diferidos com um cartão de crédito On-us"
    },
    {
        "key": "SIS0229",
        "value": "O código de pagamento diferido solicitado não existe"
    },
    {
        "key": "SIS0230",
        "value": "O estabelecimento comercial não está configurado para aceitar pagamentos fracionários ou o código de fracionamento que você nos envia não está registrado."
    },
    {
        "key": "SIS0231",
        "value": "Não há método de pagamento aplicável"
    },
    {
        "key": "SIS0232",
        "value": "Método de pagamento não disponível"
    },
    {
        "key": "SIS0233",
        "value": "Método de pagamento desconhecido"
    },
    {
        "key": "SIS0234",
        "value": "Nome do titular da conta não disponível"
    },
    {
        "key": "SIS0235",
        "value": "Campo Sis_Numero_Entidad não disponível"
    },
    {
        "key": "SIS0236",
        "value": "O valor do campo Sis_Numero_Entidad não tem o comprimento necessário"
    },
    {
        "key": "SIS0237",
        "value": "O valor do campo Sis_Numero_Entidad não é numérico"
    },
    {
        "key": "SIS0238",
        "value": "O valor do campo Sis_Numero_Oficina não está disponível"
    },
    {
        "key": "SIS0239",
        "value": "O valor do campo Sis_Numero_Oficina não tem o comprimento necessário"
    },
    {
        "key": "SIS0240",
        "value": "O valor do campo Sis_Numero_Oficina não é numérico"
    },
    {
        "key": "SIS0241",
        "value": "Campo Sis_Numero_DC não disponível"
    },
    {
        "key": "SIS0242",
        "value": "O valor do campo Sis_Numero_DC não tem o comprimento necessário"
    },
    {
        "key": "SIS0243",
        "value": "O valor do campo Sis_Numero_DC não é numérico"
    },
    {
        "key": "SIS0244",
        "value": "Campo Sis_Numero_Cuenta não disponível"
    },
    {
        "key": "SIS0245",
        "value": "O valor do campo Sis_Numero_Cuenta não tem o comprimento necessário"
    },
    {
        "key": "SIS0246",
        "value": "O valor do campo Sis_Numero_Cuenta não é numérico"
    },
    {
        "key": "SIS0247",
        "value": "Dígito de controle de conta de cliente inválido"
    },
    {
        "key": "SIS0248",
        "value": "A loja não permite pagamento por débito direto"
    },
    {
        "key": "SIS0249",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0250",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0251",
        "value": "O comerciante não permite o pagamento por transferência"
    },
    {
        "key": "SIS0252",
        "value": "Devido à sua configuração você não pode enviar o cartão"
    },
    {
        "key": "SIS0253",
        "value": "O cartão não cumpre o dígito de verificação"
    },
    {
        "key": "SIS0254",
        "value": "O número de operações IP excede o limite permitido pelo comerciante"
    },
    {
        "key": "SIS0255",
        "value": "O valor acumulado pelo IP ultrapassa o limite permitido pelo lojista"
    },
    {
        "key": "SIS0256",
        "value": "A loja não permite operações de pré-autorização"
    },
    {
        "key": "SIS0257",
        "value": "O cartão não permite operações de pré-autorização"
    },
    {
        "key": "SIS0680",
        "value": "Autenticação não concluida"
    },
    {
        "key": "SIS0258",
        "value": "Você não tem o IDETRA emissor necessário para fazer a confirmação"
    },
    {
        "key": "SIS0259",
        "value": "Não há operação original para notificar ou consultar"
    },
    {
        "key": "SIS0260",
        "value": "Entrada incorreta no SIS"
    },
    {
        "key": "SIS0261",
        "value": "Restrições excedidas"
    },
    {
        "key": "SIS0262",
        "value": "Moeda não permitida para transferência ou operação de débito direto"
    },
    {
        "key": "SIS0263",
        "value": "Erro ao calcular dados para processar a operação"
    },
    {
        "key": "SIS0264",
        "value": "Erro ao processar dados de resposta recebidos"
    },
    {
        "key": "SIS0265",
        "value": "Erro de assinatura nos dados recebidos"
    },
    {
        "key": "SIS0266",
        "value": "Não é possível recuperar os dados da operação recebida"
    },
    {
        "key": "SIS0267",
        "value": "A operação não pode ser processada porque não há código de conta do cliente"
    },
    {
        "key": "SIS0268",
        "value": "A devolução não pode ser processada por WebService"
    },
    {
        "key": "SIS0269",
        "value": "Você não pode fazer reembolsos de operações de débito direto não baixadas"
    },
    {
        "key": "SIS0270",
        "value": "O comerciante não pode realizar pré-autorizações diferidas"
    },
    {
        "key": "SIS0274",
        "value": "Tipo de operação desconhecido ou não permitido por esta entrada no SIS"
    },
    {
        "key": "SIS0275",
        "value": "Prêmio sem IdPremio"
    },
    {
        "key": "SIS0276",
        "value": "Unidades de prêmio não numéricas."
    },
    {
        "key": "SIS0277",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0278",
        "value": "Erro no processo de consulta de premiação"
    },
    {
        "key": "SIS0279",
        "value": "A loja não ativou a operação de fidelidade"
    },
    {
        "key": "SIS0280",
        "value": "Bloqueio por controle de segurança"
    },
    {
        "key": "SIS0281",
        "value": "Bloqueio por controle de segurança"
    },
    {
        "key": "SIS0282",
        "value": "Bloqueio por controle de segurança"
    },
    {
        "key": "SIS0283",
        "value": "Bloqueio por controle de segurança"
    },
    {
        "key": "SIS0284",
        "value": "Não há operação para fazer o Pagamento Adicional"
    },
    {
        "key": "SIS0285",
        "value": "Não há operação para fazer o Pagamento Adicional"
    },
    {
        "key": "SIS0286",
        "value": "A operação na qual você deseja fazer a operação adicional não é aceita"
    },
    {
        "key": "SIS0287",
        "value": "a Transação excedeu o valor do Pagamento Adicional"
    },
    {
        "key": "SIS0288",
        "value": "Nenhum outro pagamento adicional pode ser feito. o número de pagamentos foi excedido"
    },
    {
        "key": "SIS0289",
        "value": "O valor do pagamento adicional excede o máximo de dias permitido"
    },
    {
        "key": "SIS0290",
        "value": "Bloqueio por controle de segurança"
    },
    {
        "key": "SIS0291",
        "value": "Bloqueio por controle de segurança"
    },
    {
        "key": "SIS0292",
        "value": "Bloqueio por controle de segurança"
    },
    {
        "key": "SIS0293",
        "value": "Bloqueio por controle de segurança"
    },
    {
        "key": "SIS0294",
        "value": "O cartão não é privado"
    },
    {
        "key": "SIS0295",
        "value": "Erro de duplicação de operação. Você pode tentar novamente"
    },
    {
        "key": "SIS0296",
        "value": "A operação do cartão não foi encontrada no arquivo inicial"
    },
    {
        "key": "SIS0297",
        "value": "Número de operações sucessivas de Cartão em Arquivo excedido"
    },
    {
        "key": "SIS0298",
        "value": "O comerciante não permite operações de arquivo de cartão"
    },
    {
        "key": "SIS0299",
        "value": "Erro de pagamento com PayPal"
    },
    {
        "key": "SIS0300",
        "value": "Erro de pagamento com PayPal"
    },
    {
        "key": "SIS0301",
        "value": "Erro de pagamento com PayPal"
    },
    {
        "key": "SIS0302",
        "value": "Moeda inválida para pagamento do PayPal"
    },
    {
        "key": "SIS0304",
        "value": "O pagamento fracionário não é permitido se o cartão não for da FINCONSUM"
    },
    {
        "key": "SIS0305",
        "value": "O pagamento fracionário do FINCONSUM em outra moeda que não o euro não é permitido"
    },
    {
        "key": "SIS0306",
        "value": "Valor do campo Ds_Merchant_PrepaidCard inválido"
    },
    {
        "key": "SIS0307",
        "value": "Operação de vale-presente não permitida"
    },
    {
        "key": "SIS0308",
        "value": "Limite de tempo para recarregar o vale-presente excedido"
    },
    {
        "key": "SIS0309",
        "value": "Dados adicionais estão faltando para recarregar o cartão pré-pago"
    },
    {
        "key": "SIS0310",
        "value": "Valor do campo Ds_Merchant_Prepaid_Expiry inválido"
    },
    {
        "key": "SIS0311",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0319",
        "value": "O comerciante não pertence ao grupo enviado em Ds_Merchant_Group"
    },
    {
        "key": "SIS0320",
        "value": "Erro ao gerar a referência"
    },
    {
        "key": "SIS0321",
        "value": "O identificador indicado em Ds_Merchant_Identifier não está associado ao comerciante"
    },
    {
        "key": "SIS0322",
        "value": "Erro de formato em Ds_Merchant_Group"
    },
    {
        "key": "SIS0323",
        "value": "O campo Ds_Merchant_Customer_Mobile ou Ds_Merchant_Customer_Mail é obrigatório"
    },
    {
        "key": "SIS0324",
        "value": "Não foi possível enviar link para o proprietário"
    },
    {
        "key": "SIS0326",
        "value": "Os dados do cartão foram enviados na primeira fase de um pagamento em duas fases"
    },
    {
        "key": "SIS0327",
        "value": "Nenhum celular ou e-mail foi enviado na primeira fase de um pagamento em duas fases"
    },
    {
        "key": "SIS0328",
        "value": "Token de pagamento de duas fases inválido"
    },
    {
        "key": "SIS0329",
        "value": "O token de pagamento não pode ser recuperado em duas fases"
    },
    {
        "key": "SIS0330",
        "value": "Datas de pagamento de duas fases incorretas"
    },
    {
        "key": "SIS0331",
        "value": "A operação não tem um estado válido ou não existe."
    },
    {
        "key": "SIS0332",
        "value": "O valor da transação original e o reembolso devem ser idênticos"
    },
    {
        "key": "SIS0333",
        "value": "Erro em um pedido para MasterPass Wallet"
    },
    {
        "key": "SIS0334",
        "value": "Bloqueio por controle de segurança"
    },
    {
        "key": "SIS0335",
        "value": "O valor do campo Ds_Merchant_Recharge_Commission não é válido"
    },
    {
        "key": "SIS0336",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0337",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0338",
        "value": "Operação IUPAY não encontrada"
    },
    {
        "key": "SIS0339",
        "value": "A loja não possui pagamento iUPAY"
    },
    {
        "key": "SIS0340",
        "value": "Resposta inválida recebida de iUPAY"
    },
    {
        "key": "SIS0341",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0342",
        "value": "A empresa não permite a realização de operações de pagamento de impostos"
    },
    {
        "key": "SIS0343",
        "value": "O parâmetro Ds_Merchant_Tax_Reference está ausente ou incorreto"
    },
    {
        "key": "SIS0344",
        "value": "As condições de cota não foram aceitas"
    },
    {
        "key": "SIS0345",
        "value": "O número errado de parcelas foi escolhido"
    },
    {
        "key": "SIS0346",
        "value": "Erro de formato de campo DS_MERCHANT_PAY_TYPE"
    },
    {
        "key": "SIS0347",
        "value": "O estabelecimento comercial não está configurado para realizar a consulta BIN."
    },
    {
        "key": "SIS0348",
        "value": "O BIN indicado na consulta não é reconhecido"
    },
    {
        "key": "SIS0349",
        "value": "A quantidade e os dados DCC enviados não coincidem com os cadastrados no SIS"
    },
    {
        "key": "SIS0350",
        "value": "Não há dados DCC registrados no SIS para este número de pedido"
    },
    {
        "key": "SIS0351",
        "value": "Autenticação pré-paga incorreta"
    },
    {
        "key": "SIS0352",
        "value": "O tipo de assinatura do comerciante não permite esta operação"
    },
    {
        "key": "SIS0353",
        "value": "O comerciante não possui uma chave 3DES válida definida"
    },
    {
        "key": "SIS0354",
        "value": "Erro ao descriptografar a solicitação"
    },
    {
        "key": "SIS0355",
        "value": "O terminal comercial enviado nos dados criptografados não corresponde ao enviado na solicitação"
    },
    {
        "key": "SIS0356",
        "value": "Existem dados de entrada para controle de fraude e o comerciante não tem controle de fraude ativo"
    },
    {
        "key": "SIS0357",
        "value": "O comerciante tem controle de fraude ativo e não há campo ds_merchant_merchantscf"
    },
    {
        "key": "SIS0358",
        "value": "A entidade não possui pagamento iUPAY"
    },
    {
        "key": "SIS0359",
        "value": "O comerciante permite apenas o pagamento de impostos e o campo Ds_Merchant_TaxReference não está sendo informado"
    },
    {
        "key": "SIS0370",
        "value": "Erro de formato Scf_Merchant_Nif. Comprimento máximo 16"
    },
    {
        "key": "SIS0371",
        "value": "Erro de formato Scf_Merchant_Name. Comprimento máximo 30"
    },
    {
        "key": "SIS0372",
        "value": "Erro de formato Scf_Merchant_First_Name. Comprimento máximo 30"
    },
    {
        "key": "SIS0373",
        "value": "Erro de formato Scf_Merchant_Last_Name. Comprimento máximo 30"
    },
    {
        "key": "SIS0374",
        "value": "Erro de formato Scf_Merchant_User. Comprimento máximo 45"
    },
    {
        "key": "SIS0375",
        "value": "Erro de formato Scf_Affinity_Card. Valores possíveis 'S' ou 'N'. Comprimento máximo 1"
    },
    {
        "key": "SIS0376",
        "value": "Erro de formato Scf_Payment_Financed. Valores possíveis 'S' ou 'N'. Comprimento máximo 1"
    },
    {
        "key": "SIS0377",
        "value": "Erro de formato Scf_Ticket_Departure_Point. Comprimento máximo 30"
    },
    {
        "key": "SIS0378",
        "value": "Erro de formato Scf_Ticket_Destination. Comprimento máximo 30"
    },
    {
        "key": "SIS0379",
        "value": "Erro de formato Scf_Ticket_Departure_Date. Deve estar no formato aaaaMMddHHmmss."
    },
    {
        "key": "SIS0380",
        "value": "Erro de formato Scf_Ticket_Num_Passengers. Comprimento máximo 1."
    },
    {
        "key": "SIS0381",
        "value": "Erro de formato Scf_Passenger_Dni. Comprimento máximo 16."
    },
    {
        "key": "SIS0382",
        "value": "Erro de formato Scf_Passenger_Name. Comprimento máximo 30."
    },
    {
        "key": "SIS0383",
        "value": "Erro de formato Scf_Passenger_First_Name. Comprimento máximo 30."
    },
    {
        "key": "SIS0384",
        "value": "Erro de formato Scf_Passenger_Last_Name. Comprimento máximo 30."
    },
    {
        "key": "SIS0385",
        "value": "Erro de formato de Scf_Passenger_Check_Luggage. Valores possíveis 'S' ou 'N'. Comprimento máximo 1."
    },
    {
        "key": "SIS0386",
        "value": "Erro de formato Scf_Passenger_Special_luggage. Valores possíveis 'S' ou 'N'. Comprimento máximo 1."
    },
    {
        "key": "SIS0387",
        "value": "Erro de formato Scf_Passenger_Insurance_Trip. Valores possíveis 'S' ou 'N'. Comprimento máximo 1."
    },
    {
        "key": "SIS0388",
        "value": "Erro de formato Scf_Passenger_Type_Trip. Valores possíveis 'N' ou 'I'. Comprimento máximo 1."
    },
    {
        "key": "SIS0389",
        "value": "Erro de formato Scf_Passenger_Pet. Valores possíveis 'S' ou 'N'. Comprimento máximo 1."
    },
    {
        "key": "SIS0390",
        "value": "Erro de formato Scf_Order_Channel. Valores possíveis 'M' (celular), 'P' (PC) ou 'T' (tablet)"
    },
    {
        "key": "SIS0391",
        "value": "Erro de formato Scf_Order_Total_Products. Deve ter um formato numérico e comprimento máximo de 3."
    },
    {
        "key": "SIS0392",
        "value": "Erro de formato Scf_Order_Different_Products. Deve ter um formato numérico e comprimento máximo de 3."
    },
    {
        "key": "SIS0393",
        "value": "Erro de formato Scf_Order_Amount. Deve ter um formato numérico e comprimento máximo de 19."
    },
    {
        "key": "SIS0394",
        "value": "Erro de formato Scf_Order_Max_Amount. Deve ter um formato numérico e comprimento máximo de 19."
    },
    {
        "key": "SIS0395",
        "value": "Erro de formato Scf_Order_Coupon. Valores possíveis 'S' ou 'N'"
    },
    {
        "key": "SIS0396",
        "value": "Erro de formato Scf_Order_Show_Type. Deve ter comprimento máximo de 30."
    },
    {
        "key": "SIS0397",
        "value": "Erro de formato Scf_Wallet_Identifier"
    },
    {
        "key": "SIS0398",
        "value": "Erro de formato Scf_Wallet_Client_Identifier"
    },
    {
        "key": "SIS0399",
        "value": "Erro de formato Scf_Merchant_Ip_Address"
    },
    {
        "key": "SIS0400",
        "value": "Erro de formato Scf_Merchant_Proxy"
    },
    {
        "key": "SIS0401",
        "value": "Erro de formato Ds_Merchant_Mail_Phone_Number. Deve ser numérico e ter no máximo 19"
    },
    {
        "key": "SIS0402",
        "value": "Erro na chamada para SafetyPay para solicitar url de token"
    },
    {
        "key": "SIS0403",
        "value": "Erro no processo de solicitação de token de url para SafetyPay"
    },
    {
        "key": "SIS0404",
        "value": "Erro em uma solicitação para SafetyPay"
    },
    {
        "key": "SIS0405",
        "value": "Solicitação de url de token negada SAFETYPAY"
    },
    {
        "key": "SIS0406",
        "value": "O setor de comércio não está autorizado a efetuar pagamentos de prêmios de jogos de azar (jogos de azar)"
    },
    {
        "key": "SIS0407",
        "value": "O valor da operação excede o máximo permitido para efetuar o pagamento de um prêmio de jogos de azar (Jogos de Azar)"
    },
    {
        "key": "SIS0408",
        "value": "O cartão deve ter operado durante o último ano para poder efetuar o pagamento do prêmio de jogo (jogos de azar)"
    },
    {
        "key": "SIS0409",
        "value": "O cartão deve ser um Visa ou MasterCard nacional para fazer um pagamento de prêmio de jogo"
    },
    {
        "key": "SIS0410",
        "value": "Bloqueio por Operação com Cartão Privado Cajamar, em negócio não Cajamar"
    },
    {
        "key": "SIS0411",
        "value": "Não há negociação na tabela de dados adicionais do RSI Direct"
    },
    {
        "key": "SIS0412",
        "value": "A assinatura enviada não está correta"
    },
    {
        "key": "SIS0413",
        "value": "A operação foi negada pela Lynx"
    },
    {
        "key": "SIS0414",
        "value": "O plano de vendas não está correto"
    },
    {
        "key": "SIS0415",
        "value": "O tipo de produto não está correto"
    },
    {
        "key": "SIS0416",
        "value": "Quantidade não permitida em troca"
    },
    {
        "key": "SIS0417",
        "value": "Data de devolução não permitida"
    },
    {
        "key": "SIS0418",
        "value": "Não há plano de vendas atual"
    },
    {
        "key": "SIS0419",
        "value": "Tipo de conta não permitido"
    },
    {
        "key": "SIS0420",
        "value": "O comerciante não possui métodos de pagamento para esta operação"
    },
    {
        "key": "SIS0421",
        "value": "Cartão não permitido. Não é um produto Agro"
    },
    {
        "key": "SIS0422",
        "value": "Dados ausentes para operação Agro"
    },
    {
        "key": "SIS0423",
        "value": "CNPJ do trade errado"
    },
    {
        "key": "SIS0424",
        "value": "O estabelecimento não foi encontrado"
    },
    {
        "key": "SIS0425",
        "value": "Cartão não encontrado"
    },
    {
        "key": "SIS0426",
        "value": "O roteamento não é válido para o comerciante"
    },
    {
        "key": "SIS0427",
        "value": "A conexão com CECA não foi possível"
    },
    {
        "key": "SIS0428",
        "value": "Operação de débito insegura"
    },
    {
        "key": "SIS0429",
        "value": "Erro de versão (Ds_SignatureVersion)"
    },
    {
        "key": "SIS0430",
        "value": "Falha ao decodificar o parâmetro Ds_MerchantParameters"
    },
    {
        "key": "SIS0431",
        "value": "Objeto JSON enviado codificado em erro de parâmetro Ds_MerchantParameters"
    },
    {
        "key": "SIS0432",
        "value": "FUC do comércio errôneo"
    },
    {
        "key": "SIS0433",
        "value": "Terminal de negociação errado"
    },
    {
        "key": "SIS0434",
        "value": "Formato de pedido inválido"
    },
    {
        "key": "SIS0435",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0436",
        "value": "<REQUEST> construção do elemento falhou"
    },
    {
        "key": "SIS0437",
        "value": "Falha na construção do elemento <DS_SIGNATUREVERSION>"
    },
    {
        "key": "SIS0438",
        "value": "Erro na construção do elemento <DATOSENTRADA>"
    },
    {
        "key": "SIS0439",
        "value": "<DS_SIGNATURE> a construção do elemento falhou"
    },
    {
        "key": "SIS0440",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0442",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0443",
        "value": "O pagamento não é permitido em terminais ONEY com cartões estrangeiros"
    },
    {
        "key": "SIS0444",
        "value": "Tentativa de acesso usando assinaturas antigas e o comerciante está configurado como HMAC SHA256"
    },
    {
        "key": "SIS0445",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0446",
        "value": "Para terminais Oney é obrigatório indicar a forma de pagamento"
    },
    {
        "key": "SIS0448",
        "value": "O comerciante não possui a forma de pagamento Diners"
    },
    {
        "key": "SIS0449",
        "value": "O comerciante configurou \"Proibir Pagamento A\""
    },
    {
        "key": "SIS0450",
        "value": "O comerciante configurou \"Proibir Pagamento PARA AMEX\""
    },
    {
        "key": "SIS0451",
        "value": "O comerciante possui a forma de pagamento \"Proibir Pagamento A\""
    },
    {
        "key": "SIS0453",
        "value": "O comerciante não possui uma forma de pagamento configurada como \"Pagamento JCB\""
    },
    {
        "key": "SIS0454",
        "value": "O comerciante não possui uma forma de pagamento configurada como \"Pagamento Amex\""
    },
    {
        "key": "SIS0455",
        "value": "O lojista não possui a forma de pagamento \"Cartões Próprios\""
    },
    {
        "key": "SIS0456",
        "value": "O comerciante não tem uma segunda tentativa configurada"
    },
    {
        "key": "SIS0459",
        "value": "O estabelecimento comercial não possui a forma de pagamento \"Pagamento JCB\" configurada"
    },
    {
        "key": "SIS0460",
        "value": "O lojista não possui a forma de pagamento \"Pagamento AMEX\""
    },
    {
        "key": "SIS0461",
        "value": "O lojista não possui a forma de pagamento \"Pagamento AMEX\""
    },
    {
        "key": "SIS0462",
        "value": "Método de pagamento seguro não disponível para entrada Host para Host"
    },
    {
        "key": "SIS0463",
        "value": "O comerciante está tentando realizar uma operação insegura sem um método de pagamento Inseguro"
    },
    {
        "key": "SIS0464",
        "value": "O lojista não possui a forma de pagamento \"MasterCard Comercial\""
    },
    {
        "key": "SIS0465",
        "value": "O comerciante não possui o método de pagamento \"Tradicional Mundial\""
    },
    {
        "key": "SIS0466",
        "value": "A referência que está sendo usada não existe."
    },
    {
        "key": "SIS0467",
        "value": "A referência que está sendo usada tem o registro cancelado"
    },
    {
        "key": "SIS0468",
        "value": "Uma referência que foi gerada com um adquirente diferente do adquirente que a usa está sendo usada."
    },
    {
        "key": "SIS0469",
        "value": "O processo de fraude MR não foi aprovado"
    },
    {
        "key": "SIS0470",
        "value": "A solicitação do primeiro fator falhou."
    },
    {
        "key": "SIS0471",
        "value": "Erro de URL de redirecionamento de solicitação de primeiro fator.PPII"
    },
    {
        "key": "SIS0472",
        "value": "Falha ao montar o pedido de autenticação PPII"
    },
    {
        "key": "SIS0473",
        "value": "a resposta da solicitação de autenticação PPII é nula."
    },
    {
        "key": "SIS0474",
        "value": "O statusCode da resposta do pedido de autenticação PPII é nulo"
    },
    {
        "key": "SIS0475",
        "value": "A idOperation da resposta do pedido de autenticação PPII é nula"
    },
    {
        "key": "SIS0476",
        "value": "Erro ao lidar com a resposta de autenticação PPII"
    },
    {
        "key": "SIS0477",
        "value": "O tempo definido entre as etapas 1 e 2 do PPI foi excedido"
    },
    {
        "key": "SIS0478",
        "value": "Erro ao lidar com a resposta de autorização PPII"
    },
    {
        "key": "SIS0479",
        "value": "A resposta do pedido de autorização PPII é nula"
    },
    {
        "key": "SIS0480",
        "value": "O statusCode da resposta do pedido de autorização PPII é nulo."
    },
    {
        "key": "SIS0481",
        "value": "O comércio não é um facilitador de pagamento"
    },
    {
        "key": "SIS0482",
        "value": "A idOperación da resposta de um OK de Autorização é nula ou não corresponde ao idOp. do Auth."
    },
    {
        "key": "SIS0483",
        "value": "A resposta da solicitação de retorno PPII é nula."
    },
    {
        "key": "SIS0484",
        "value": "O statusCode ou idPetition da resposta da solicitação de retorno PPII é nulo."
    },
    {
        "key": "SIS0485",
        "value": "BIZUM retornou um KO no retorno"
    },
    {
        "key": "SIS0486",
        "value": "A resposta à consulta PPII é nula"
    },
    {
        "key": "SIS0487",
        "value": "O comerciante não tem o método de pagamento Paygold habilitado"
    },
    {
        "key": "SIS0488",
        "value": "O lojista não possui a forma de pagamento \"Pagamento MOTO / Manual\""
    },
    {
        "key": "SIS0489",
        "value": "Operação MPI externa não permitida"
    },
    {
        "key": "SIS0490",
        "value": "Parâmetros MPI são recebidos Suporte na operação MPI externa"
    },
    {
        "key": "SIS0491",
        "value": "SecLevel não permitido em operação MPI externa"
    },
    {
        "key": "SIS0492",
        "value": "Parâmetros MPI externos são recebidos no suporte de operação MPI"
    },
    {
        "key": "SIS0493",
        "value": "Parâmetros MPI são recebidos em operação insegura"
    },
    {
        "key": "SIS0494",
        "value": "Assinatura Obsoleta"
    },
    {
        "key": "SIS0495",
        "value": "Configuração incorreta de ApplePay ou AndroidPay"
    },
    {
        "key": "SIS0496",
        "value": "Você não tem a forma de pagamento AndroidPay registrada"
    },
    {
        "key": "SIS0497",
        "value": "Você não tem o método de pagamento ApplePay registrado"
    },
    {
        "key": "SIS0498",
        "value": "A moeda / valor da transação ApplePay não correspondem"
    },
    {
        "key": "SIS0499",
        "value": "Erro ao obter chaves do comerciante no Google / Apple Pay"
    },
    {
        "key": "SIS0500",
        "value": "Erro de DCC dinâmico, o cartão foi modificado."
    },
    {
        "key": "SIS0501",
        "value": "Erro na validação dos dados enviados para gerar o Id da operação"
    },
    {
        "key": "SIS0502",
        "value": "Erro ao validar Id Oper"
    },
    {
        "key": "SIS0503",
        "value": "Erro ao validar o pedido"
    },
    {
        "key": "SIS0504",
        "value": "Erro ao validar o tipo de transação"
    },
    {
        "key": "SIS0505",
        "value": "Erro ao validar moeda"
    },
    {
        "key": "SIS0506",
        "value": "Erro ao validar o valor"
    },
    {
        "key": "SIS0507",
        "value": "Id Oper não é válido"
    },
    {
        "key": "SIS0508",
        "value": "Erro ao validar Id Oper"
    },
    {
        "key": "SIS0510",
        "value": "O envio de dados do cartão não é permitido se o ID da operação for enviado"
    },
    {
        "key": "SIS0511",
        "value": "Falha na resposta da consulta de BINES"
    },
    {
        "key": "SIS0515",
        "value": "O comerciante tem o pagamento Amex ativado no Perfil."
    },
    {
        "key": "SIS0516",
        "value": "Erro ao montar a mensagem China Union Pay"
    },
    {
        "key": "SIS0517",
        "value": "Falha ao definir a chave para China Union Pay"
    },
    {
        "key": "SIS0518",
        "value": "Erro ao salvar dados para pagamento China Union Pay"
    },
    {
        "key": "SIS0519",
        "value": "Mensagem de autenticação ruim"
    },
    {
        "key": "SIS0520",
        "value": "A mensagem da sessão SecurePlus está vazia"
    },
    {
        "key": "SIS0521",
        "value": "O xml de resposta vem vazio"
    },
    {
        "key": "SIS0522",
        "value": "Nenhum parâmetro foi recebido nos dados de entrada"
    },
    {
        "key": "SIS0523",
        "value": "A assinatura calculada não corresponde à recebida na resposta"
    },
    {
        "key": "SIS0524",
        "value": "o resultado da autenticação 3DSecure MasterCard é PARes \"N\" e não recebemos CAVV do emissor"
    },
    {
        "key": "SIS0525",
        "value": "O cartão privado não pode ser usado nesta loja"
    },
    {
        "key": "SIS0526",
        "value": "O cartão não é chinês"
    },
    {
        "key": "SIS0527",
        "value": "O parâmetro obrigatório DS_MERCHANT_BUYERID está ausente"
    },
    {
        "key": "SIS0528",
        "value": "Formato errado do parâmetro DS_MERCHANT_BUYERID na operação da Sodexo Brasil"
    },
    {
        "key": "SIS0529",
        "value": "A operação recorrente não é permitida em pagamentos com cartão Voucher"
    },
    {
        "key": "SIS0530",
        "value": "A data de cancelamento não pode exceder a data de pré-autorização em mais de 7 dias."
    },
    {
        "key": "SIS0531",
        "value": "A data de cancelamento não pode exceder a data de pré-autorização adiada em mais de 72 horas."
    },
    {
        "key": "SIS0532",
        "value": "A moeda do pedido não corresponde à devolvida"
    },
    {
        "key": "SIS0533",
        "value": "O valor do pedido não corresponde ao valor devolvido"
    },
    {
        "key": "SIS0534",
        "value": "Nenhuma referência de cobrança ou recibo / tributo emitida é recebida"
    },
    {
        "key": "SIS0535",
        "value": "Pagamento de recibo / homenagem após o prazo"
    },
    {
        "key": "SIS0536",
        "value": "Recibo / homenagem já pago"
    },
    {
        "key": "SIS0537",
        "value": "Recibo / pagamento de tributo negado pela entidade"
    },
    {
        "key": "SIS0538",
        "value": "Rejeição de recebimento / pagamento de tributo"
    },
    {
        "key": "SIS0539",
        "value": "Erro de envio de SMS"
    },
    {
        "key": "SIS0540",
        "value": "O celular enviado é muito longo (mais de 12 posições)"
    },
    {
        "key": "SIS0541",
        "value": "A referência enviada é muito longa (mais de 40 posições)"
    },
    {
        "key": "SIS0542",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "SIS0543",
        "value": "Erro, o cartão da operação é DINERS e o lojista não possui a forma de pagamento \"Pagamento DINERS\" ou \"Descubra Pagamento Não Seguro\""
    },
    {
        "key": "SIS0544",
        "value": "Erro, o cartão da operação é DINERS e o lojista não possui a forma de pagamento \"Discover Non- Secure Payment\""
    },
    {
        "key": "SIS0545",
        "value": "DISCOVER erro"
    },
    {
        "key": "SIS0546",
        "value": "DISCOVER erro"
    },
    {
        "key": "SIS0547",
        "value": "DISCOVER erro"
    },
    {
        "key": "SIS0548",
        "value": "DISCOVER erro"
    },
    {
        "key": "SIS0549",
        "value": "DISCOVER erro"
    },
    {
        "key": "SIS0550",
        "value": "ERROR no gerenciador de envio de SMS. Verifique com o suporte"
    },
    {
        "key": "SIS0551",
        "value": "ERROR no processo de autenticação."
    },
    {
        "key": "SIS0552",
        "value": "ERROR o resultado da autenticação PARes 'U'"
    },
    {
        "key": "SIS0553",
        "value": "ERRO, foi feita uma tentativa de efetuar um pagamento com o método de pagamento UPI e o cartão não é chinês"
    },
    {
        "key": "SIS0554",
        "value": "ERROR o resultado da autenticação para UPI é PARes 'U' e o comerciante não possui métodos de pagamento não seguros UPI EXPRESSPAY"
    },
    {
        "key": "SIS0555",
        "value": "ERROR o IP de conexão do módulo de gerenciamento não está entre os permitidos."
    },
    {
        "key": "SIS0556",
        "value": "O pagamento tradicional é enviado e o comércio não tem um pagamento tradicional mundial ou tradicional da UE."
    },
    {
        "key": "SIS0557",
        "value": "O pagamento com cartão em arquivo é enviado e o comerciante não tem um pagamento tradicional mundial ou tradicional da UE."
    },
    {
        "key": "SIS0558",
        "value": "ERRO, o formato de data dsMerchantP2FExpiryDate está errado"
    },
    {
        "key": "SIS0559",
        "value": "ERROR o id de operação da resposta na autenticação PPII é nulo ou não foi obtido na autenticação final"
    },
    {
        "key": "SIS0560",
        "value": "ERROR ao enviar a notificação de autenticação ao comerciante"
    },
    {
        "key": "SIS0561",
        "value": "ERROR idOperation da resposta de uma confirmação separada OK é nula ou não corresponde ao idOp. Confirmação."
    },
    {
        "key": "SIS0562",
        "value": "ERROR a resposta do pedido de confirmação separado de PPII é nula."
    },
    {
        "key": "SIS0563",
        "value": "ERROR no tratamento da resposta da confirmação separada de PPII."
    },
    {
        "key": "SIS0564",
        "value": "ERRO na operação DCC"
    },
    {
        "key": "SIS0565",
        "value": "O formato do valor do campo Ds_Merchant_Amount excede o limite permitido."
    },
    {
        "key": "SIS0566",
        "value": "Erro de acesso ao novo servidor criptográfico."
    },
    {
        "key": "SIS0567",
        "value": "ERROR foi feita uma tentativa de efetuar um pagamento com um cartão UPI chinês e o comerciante não possui um método de pagamento UPI"
    },
    {
        "key": "SIS0568",
        "value": "Operação de solicitação de inicialização rejeitada, tipo errado de transação"
    },
    {
        "key": "SIS0569",
        "value": "Petição de Operação de Iniciação rejeitada, o cartão não foi relatado"
    },
    {
        "key": "SIS0570",
        "value": "Petição de Operação de Iniciação rejeitada, cartão e referência foram enviados"
    },
    {
        "key": "SIS0571",
        "value": "Operação de autenticação EMV3DS rejeitada, protocolo versão não indicada"
    },
    {
        "key": "SIS0572",
        "value": "Operação de autenticação EMV3DS rejeitada, versão de protocolo não reconhecida"
    },
    {
        "key": "SIS0573",
        "value": "Operação de autenticação EMV3DS rejeitada, browserAcceptHeader não indicado"
    },
    {
        "key": "SIS0574",
        "value": "Operação de autenticação EMV3DS rejeitada, browserUserAgent não indicado"
    },
    {
        "key": "SIS0575",
        "value": "Operação de autenticação EMV3DS rejeitada, browserJavaEnabled não indicado"
    },
    {
        "key": "SIS0576",
        "value": "Operação de autenticação EMV3DS rejeitada, browserLanguage não indicado"
    },
    {
        "key": "SIS0577",
        "value": "Operação de autenticação EMV3DS rejeitada, browserColorDepth não indicado"
    },
    {
        "key": "SIS0578",
        "value": "Operação de autenticação EMV3DS rejeitada, browserScreenHeight não indicado"
    },
    {
        "key": "SIS0579",
        "value": "Operação de autenticação EMV3DS rejeitada, browserScreenWidth não indicado"
    },
    {
        "key": "SIS0580",
        "value": "Operação de autenticação EMV3DS rejeitada, navegadorTZ não indicado"
    },
    {
        "key": "SIS0581",
        "value": "Operação de autenticação EMV3DS rejeitada, os dados DS_MERCHANT_EMV3DS não são indicados ou são muito grandes e não podem ser convertidos para JSON"
    },
    {
        "key": "SIS0582",
        "value": "Operação de autenticação EMV3DS rejeitada, threeDSServerTransID não indicada"
    },
    {
        "key": "SIS0583",
        "value": "Operação de autenticação EMV3DS rejeitada, threeDSCompInd não indicada"
    },
    {
        "key": "SIS0584",
        "value": "Operação de autenticação EMV3DS rejeitada, notificaçãoURL não indicada"
    },
    {
        "key": "SIS0585",
        "value": "Os dados EMV 3DS enviados não correspondem aos dados obtidos na solicitação inicial"
    },
    {
        "key": "SIS0586",
        "value": "Operação de autenticação EMV3DS rejeitada, PARs não indicados"
    },
    {
        "key": "SIS0587",
        "value": "Operação de autenticação EMV3DS rejeitada, MD não indicado"
    },
    {
        "key": "SIS0588",
        "value": "Operação de autenticação EMV3DS rejeitada, versão não corresponde entre mensagens AuthenticationData e ChallengeResponse"
    },
    {
        "key": "SIS0589",
        "value": "Operação de autenticação EMV3DS rejeitada, resposta sem CRes"
    },
    {
        "key": "SIS0590",
        "value": "Operação de autenticação EMV3DS rejeitada, resposta de CRes de desmontagem de erro"
    },
    {
        "key": "SIS0591",
        "value": "Operação de autenticação EMV3DS rejeitada, resposta de erro CRes vem sem threeDSServerTransID"
    },
    {
        "key": "SIS0592",
        "value": "Erro de operação de autenticação EMV3DS"
    },
    {
        "key": "SIS0593",
        "value": "Erro na operação de autenticação EMV3DS, o transStatus da consulta final da operação não está definido."
    },
    {
        "key": "SIS0594",
        "value": "Operação de autenticação EMV3DS rejeitada, CRes não indicados"
    },
    {
        "key": "SIS0595",
        "value": "O comerciante indicado não possui métodos de pagamento seguros permitidos no EMV3DS V2"
    },
    {
        "key": "SIS0596",
        "value": "Operação de solicitação de início rejeitada, moeda errada"
    },
    {
        "key": "SIS0597",
        "value": "Operação para iniciar a solicitação rejeitada, valor incorreto"
    },
    {
        "key": "SIS0598",
        "value": "A operação de autenticação EMV3DS falhou, o fallback para 3DSecure v1 não é permitido."
    },
    {
        "key": "SIS0599",
        "value": "Erro na operação de autenticação EMV3DS"
    },
    {
        "key": "SIS0600",
        "value": "Falha no processo de autenticação 3DSecure v2 - Resposta Areq N"
    },
    {
        "key": "SIS0601",
        "value": "Falha no processo de autenticação 3DSecure v2 - Resposta Areq R"
    },
    {
        "key": "SIS0602",
        "value": "Falha no processo de autenticação 3DSecure v2 - Resposta Areq U"
    },
    {
        "key": "SIS0644",
        "value": "Erro no processo de autenticação 3DSecure v2 - Envio de dados da entrada StartRequest para a entrada TrataPetición"
    },
    {
        "key": "SIS0603",
        "value": "Erro no parâmetro DS_MERCHANT_DCC do DCC enviado na operação H2H (REST e SOAP)"
    },
    {
        "key": "SIS0604",
        "value": "Erro nos dados DCC enviados no parâmetro DS_MERCHANT_DCC na operação H2H (REST e SOAP)"
    },
    {
        "key": "SIS0605",
        "value": "Erro no parâmetro DS_MERCHANT_MPIEXTERNAL enviado na operação H2H (REST e SOAP)"
    },
    {
        "key": "SIS0606",
        "value": "Erro nos dados MPI enviados no parâmetro DS_MERCHANT_MPIEXTERNAL na operação H2H (REST e SOAP)"
    },
    {
        "key": "SIS0607",
        "value": "Erro de parâmetro MPI TXID enviado no parâmetro DS_MERCHANT_MPIEXTERNAL na operação H2H (REST e SOAP) está errado"
    },
    {
        "key": "SIS0608",
        "value": "Erro de parâmetro MPI CAVV enviado no parâmetro DS_MERCHANT_MPIEXTERNAL na operação H2H (REST e SOAP) está errado"
    },
    {
        "key": "SIS0609",
        "value": "Erro de parâmetro MPI ECI enviado no parâmetro DS_MERCHANT_MPIEXTERNAL na operação H2H (REST e SOAP) está errado"
    },
    {
        "key": "SIS0610",
        "value": "Erro de parâmetro MPI threeDSServerTransID enviado no parâmetro DS_MERCHANT_MPIEXTERNAL na operação H2H (REST e SOAP) está errado"
    },
    {
        "key": "SIS0611",
        "value": "O erro do parâmetro MPI dsTransID enviado no parâmetro DS_MERCHANT_MPIEXTERNAL na operação H2H (REST e SOAP) está errado"
    },
    {
        "key": "SIS0612",
        "value": "Erro de parâmetro MPI authenticacionValue enviado no parâmetro DS_MERCHANT_MPIEXTERNAL na operação H2H (REST e SOAP) é incorreto"
    },
    {
        "key": "SIS0613",
        "value": "Erro de parâmetro MPI protocolVersion enviado no parâmetro DS_MERCHANT_MPIEXTERNAL na operação H2H (REST e SOAP) está errado"
    },
    {
        "key": "SIS0614",
        "value": "Erro de parâmetro MPI Eci enviado no parâmetro DS_MERCHANT_MPIEXTERNAL na operação H2H (REST e SOAP) está errado"
    },
    {
        "key": "SIS0615",
        "value": "Erro externo de MPI, marca do cartão não permitida"
    },
    {
        "key": "SIS0616",
        "value": "Erro de parâmetro DS_MERCHANT_EXCEP_SCA com valor incorreto"
    },
    {
        "key": "SIS0617",
        "value": "Erro do parâmetro DS_MERCHANT_EXCEP_SCA é do tipo MIT e não vem dados de COF ou de pagamento por referência"
    },
    {
        "key": "SIS0618",
        "value": "Erro a isenção enviada não é permitida e o comerciante não está pronto para autenticar"
    },
    {
        "key": "SIS0619",
        "value": "O comerciante não possui o método de pagamento Amazon"
    },
    {
        "key": "SIS0620",
        "value": "Erro DCC para valor maior do que o permitido"
    },
    {
        "key": "SIS0621",
        "value": "O amazonOrderReferenceId é inválido"
    },
    {
        "key": "SIS0622",
        "value": "Falha ao realizar cálculos DCC na operação"
    },
    {
        "key": "SIS0623",
        "value": "Falha ao realizar cálculos DCC na operação"
    },
    {
        "key": "SIS0624",
        "value": "Falha ao realizar cálculos DCC na operação"
    },
    {
        "key": "SIS0625",
        "value": "Erro ao cancelar o pagamento, pois já existe um reembolso associado a esse pagamento"
    },
    {
        "key": "SIS0626",
        "value": "Erro na devolução do pagamento, já existe um cancelamento da operação a ser devolvida"
    },
    {
        "key": "SIS0627",
        "value": "O número de referência ou pedido enviado pelo CRTM não é válido."
    },
    {
        "key": "SIS0628",
        "value": "Erro, a operação vem com dados 3DSecure e vem da entrada SERMEPA"
    },
    {
        "key": "SIS0629",
        "value": "Erro, não há operação de confirmação separada para abortar"
    },
    {
        "key": "SIS0630",
        "value": "A substituição do commit separado falhou, já existe um retorno associado ao commit separado"
    },
    {
        "key": "SIS0631",
        "value": "Falha na substituição de confirmação separada, já existe uma substituição associada a confirmação separada"
    },
    {
        "key": "SIS0632",
        "value": "Erro, a confirmação separada na qual você deseja cancelar não está autorizada"
    },
    {
        "key": "SIS0633",
        "value": "A data de cancelamento não pode exceder os dias configurados para a confirmação separada."
    },
    {
        "key": "SIS0634",
        "value": "Não existe erro na operação de pagamento sobre a qual fazer o cancelamento"
    },
    {
        "key": "SIS0635",
        "value": "Erro de cancelamento de pagamento, já existe um cancelamento associado ao pagamento"
    },
    {
        "key": "SIS0636",
        "value": "Erro, o pagamento que você deseja cancelar não está autorizado"
    },
    {
        "key": "SIS0637",
        "value": "A data de cancelamento não pode ultrapassar os dias configurados para pagamento."
    },
    {
        "key": "SIS0638",
        "value": "Erro, há mais de uma devolução que você deseja cancelar e qual não foi especificada."
    },
    {
        "key": "SIS0639",
        "value": "Erro, não há operação de retorno para cancelar"
    },
    {
        "key": "SIS0640",
        "value": "Erro a confirmação separada na qual você deseja cancelar não está autorizada ou já foi cancelada"
    },
    {
        "key": "SIS0641",
        "value": "A data de cancelamento não pode ultrapassar os dias fixados para a devolução."
    },
    {
        "key": "SIS0642",
        "value": "A data da pré-autorização a ser substituída não pode exceder 30 dias"
    },
    {
        "key": "SIS0643",
        "value": "Erro ao obter a personalização do comerciante"
    },
    {
        "key": "SIS0650",
        "value": "Erro, o MAC não está correto na mensagem de pagamento de impostos"
    },
    {
        "key": "SIS0651",
        "value": "SCA é necessário e o comerciante não está preparado para autenticar."
    },
    {
        "key": "SIS0652",
        "value": "Erro a isenção e a configuração do lojista não requerem SCA e o lojista não está configurado para autorizar com essa marca de cartão"
    },
    {
        "key": "SIS0653",
        "value": "Operação de autenticação rejeitada, browserJavascriptEnabled não indicado"
    },
    {
        "key": "SIS0654",
        "value": "Erro, apenas Ds_MerchantOTA é permitido"
    },
    {
        "key": "SIS0655",
        "value": "Erro, dados recorrentes 3RI são indicados e a versão enviada na solicitação não é 2.2"
    },
    {
        "key": "SIS0656",
        "value": "Erro, dados recorrentes 3RI são indicados e o campo threeDSRequestorPriorAuthenticationInfo no RequestTalk está vazio"
    },
    {
        "key": "SIS0657",
        "value": "Erro, dados 3RI-OTA são indicados e a versão enviada na solicitação não é 2.2"
    },
    {
        "key": "SIS0658",
        "value": "Erro, dados 3RI-OTA são indicados e o campo threeDSRequestorPriorAuthenticationInfo no RequestTalk está vazio"
    },
    {
        "key": "SIS0659",
        "value": "Erro, dados 3RI-OTA são indicados para Mestre e o campo threeDSReqPriorRef do campo threeDSRequestorPriorAuthenticationInfo na solicitação de solicitação está vazio"
    },
    {
        "key": "SIS0660",
        "value": "Erro, dados 3RI-OTA são indicados para Mestre e o campo authenticationValue no manipulador de solicitação está vazio"
    },
    {
        "key": "SIS0661",
        "value": "Erro, dados 3RI-OTA são indicados para Mestre e o campo Eci no manipulador de Solicitação está vazio"
    },
    {
        "key": "SIS0662",
        "value": "Erro, a negociação não está entre as que podem fazer confirmações parciais."
    },
    {
        "key": "SIS0663",
        "value": "Não há dados para iniciar petição que correspondam aos enviados pelo comerciante na mensagem Trata Petition"
    },
    {
        "key": "SIS0664",
        "value": "O elemento 3DS Server Transaction Id não é enviado na mensagem Request Request e este elemento existe na mensagem Request Initiate"
    },
    {
        "key": "SIS0665",
        "value": "A moeda indicada pelo comerciante na mensagem Trata Petição não corresponde à enviada na mensagem Iniciar Petição"
    },
    {
        "key": "SIS0666",
        "value": "A quantidade indicada pelo comerciante na mensagem Trata Petição não corresponde à quantidade enviada na mensagem Iniciar Petição"
    },
    {
        "key": "SIS0667",
        "value": "O tipo de operação indicada pelo estabelecimento na mensagem Solicitar Solicitação não corresponde ao enviado na mensagem Solicitar Iniciar"
    },
    {
        "key": "SIS0668",
        "value": "A referência indicada pelo estabelecimento na mensagem de Solicitação de Solicitação não corresponde à enviada na mensagem de Solicitação de Iniciação"
    },
    {
        "key": "SIS0669",
        "value": "O Id Oper Insite indicado pelo comerciante na mensagem Trata da Petição não corresponde ao enviado na mensagem da Petição de Iniciado"
    },
    {
        "key": "SIS0670",
        "value": "O cartão indicado pelo comerciante na mensagem Trata Petição não corresponde ao enviado na mensagem Iniciar Petição"
    },
    {
        "key": "SIS0671",
        "value": "Negação por TRA Lynx"
    },
    {
        "key": "SIS0672",
        "value": "A autenticação falhou. Trave após três tentativas."
    },
    {
        "key": "SIS0673",
        "value": "Operação cancelada. O usuário não deseja continuar."
    },
    {
        "key": "SIS0674",
        "value": "Assinatura rejeitada pelo beneficiário."
    },
    {
        "key": "SIS0675",
        "value": "Carga rejeitada pelo originador."
    },
    {
        "key": "SIS0676",
        "value": "O processador rejeita a operação."
    },
    {
        "key": "SIS0677",
        "value": "Saldo disponível insuficiente."
    },
    {
        "key": "SIS0678",
        "value": "A versão do 3DSecure indicada na Petição Trata está errada ou é superior àquela retornada no início da petição."
    },
    {
        "key": "SIS0681",
        "value": "Falha ao inserir dados de autenticação em uma operação com MPI externo"
    },
    {
        "key": "SIS0682",
        "value": "Consulte a TRA. O parâmetro Ds_Merchant_TRA_Data está errado"
    },
    {
        "key": "SIS0683",
        "value": "Consulte a TRA. O parâmetro Ds_Merchant_TRA_Type está ausente"
    },
    {
        "key": "SIS0684",
        "value": "Consulte a TRA. O parâmetro Ds_Merchant_TRA_Type tem um valor não permitido"
    },
    {
        "key": "SIS0685",
        "value": "Consulte a TRA. O perfil do comerciante não permite isenção TRA"
    },
    {
        "key": "SIS0686",
        "value": "Consulte a TRA. As configurações do comerciante não permitem que você use Redsys TRA"
    },
    {
        "key": "SIS0687",
        "value": "Atualização do resultado do TRA. O parâmetro Ds_Merchant_TRA_ResultAuth está ausente"
    },
    {
        "key": "SIS0688",
        "value": "Atualização do resultado do TRA. O parâmetro Ds_Merchant_TRA_ResultAuth tem um valor ilegal"
    },
    {
        "key": "SIS0689",
        "value": "Atualização do resultado do TRA. A operação é do tipo resultado de atualização e a operação de consulta anterior não foi localizada."
    },
    {
        "key": "SIS0690",
        "value": "Atualização do resultado do TRA. A atualização do resultado falhou"
    },
    {
        "key": "SIS0692",
        "value": "Pedido de entrada PSP para um terminal que não está associado a um PSP"
    },
    {
        "key": "SIS0802",
        "value": "Envie um valor incorreto no parâmetro DS_MERCHANT_COF_TYPE"
    },
    {
        "key": "SIS0809",
        "value": "Não é possível realizar uma operação de pré-autorização recorrente"
    },
    {
        "key": "SIS0810",
        "value": "Não é possível realizar operação parcelada e recorrente"
    },
    {
        "key": "101",
        "value": "cartão expirado"
    },
    {
        "key": "106",
        "value": "Cartão bloqueado, excesso de pin errado"
    },
    {
        "key": "129",
        "value": "CVV incorreto"
    },
    {
        "key": "180",
        "value": "Cartão inválido ou (LinX)"
    },
    {
        "key": "184",
        "value": "o cliente não autenticou"
    },
    {
        "key": "190",
        "value": "Negação do emissor"
    },
    {
        "key": "904",
        "value": "Problema de configuração de comércio"
    },
    {
        "key": "915",
        "value": "O titular cancelou a transação de pagamento."
    },
    {
        "key": "8102",
        "value": "Operação que foi redirecionada ao emissor para autenticar EMV3DS V1 (para H2H)"
    },
    {
        "key": "8210",
        "value": "Operação que foi redirecionada ao emissor para autenticar EMV3DS V2.1 (para H2H)"
    },
    {
        "key": "8220",
        "value": "Operação que foi redirecionada ao emissor para autenticar EMV3DS V2.2 (para H2H)"
    },
    {
        "key": "9700",
        "value": "PayPal retornou um KO"
    },
    {
        "key": "9801",
        "value": "Negado por iUPAY"
    },
    {
        "key": "9850",
        "value": "Erro na operação realizada com Amazon Pay"
    },
    {
        "key": "9860",
        "value": "Erro na operação realizada com Amazon Pay. A operação pode ser repetida"
    },
    {
        "key": "9899",
        "value": "Os dados em Ds_Merchant_Data e não foram assinados corretamente"
    },
    {
        "key": "9900",
        "value": "SafetyPay retornou um KO"
    },
    {
        "key": "9909",
        "value": "Erro genérico. Verifique com o suporte"
    },
    {
        "key": "9912",
        "value": "A operação teve um problema e um cancelamento automático deve ser gerado"
    },
    {
        "key": "9913",
        "value": "Erro ao enviar notificação SOAP"
    },
    {
        "key": "9914",
        "value": "Resposta KO pelo comerciante na notificação SOAP"
    },
    {
        "key": "9915",
        "value": "A pedido do usuário, o pagamento é cancelado"
    },
    {
        "key": "9928",
        "value": "A pedido do usuário, a pré-autorização é cancelada"
    },
    {
        "key": "9929",
        "value": "O proprietário cancelou a operação"
    },
    {
        "key": "9930",
        "value": "A transferência está pendente"
    },
    {
        "key": "9931",
        "value": "Negado (LINX)"
    },
    {
        "key": "9932",
        "value": "Negado (LINX)"
    },
    {
        "key": "9933",
        "value": "Negado (LINX)"
    },
    {
        "key": "9934",
        "value": "Negado (LINX)"
    },
    {
        "key": "9935",
        "value": "Negado (LINX)"
    },
    {
        "key": "9966",
        "value": "BIZUM retornou um KO na autorização"
    },
    {
        "key": "9992",
        "value": "Aplicação PAE"
    },
    {
        "key": "9994",
        "value": "Nenhum cartão foi selecionado na carteira."
    },
    {
        "key": "9995",
        "value": "Recarga pré-paga negada"
    },
    {
        "key": "9996",
        "value": "Não permite recarga de cartão pré-pago"
    },
    {
        "key": "9997",
        "value": "Com o mesmo cartão existem vários pagamentos em \"flight\""
    },
    {
        "key": "9998",
        "value": "Operação em processo de solicitação de dados do cartão"
    },
    {
        "key": "9999",
        "value": "Operação que foi redirecionada ao emissor para autenticar"
    },
    {
        "key": "XML0000",
        "value": "Erros no processamento da string XML recebida"
    },
    {
        "key": "XML0001",
        "value": "Erro de geração de DOM do XML-String recebido e DTD definido"
    },
    {
        "key": "XML0002",
        "value": "Não há elemento \"Message\" na string XML recebida"
    },
    {
        "key": "XML0003",
        "value": "O tipo de \"Mensagem\" na string XML recebida tem um valor desconhecido ou inválido na solicitação"
    },
    {
        "key": "XML0004",
        "value": "O elemento \"Ds_MerchantCode\" não existe no XML- String recebido"
    },
    {
        "key": "XML0005",
        "value": "O elemento \"Ds_MerchantCode\" está vazio no XML- String recebido"
    },
    {
        "key": "XML0006",
        "value": "O elemento \"Ds_MerchantCode\" tem um comprimento incorreto no XML- String recebido"
    },
    {
        "key": "XML0007",
        "value": "O elemento \"Ds_MerchantCode\" não tem um formato numérico no XML- String recebido"
    },
    {
        "key": "XML0008",
        "value": "O elemento \"Ds_Terminal\" não existe no XML- String recebido"
    },
    {
        "key": "XML0009",
        "value": "O elemento \"Ds_Terminal\" está vazio no XML- String recebido"
    },
    {
        "key": "XML0010",
        "value": "O elemento \"Ds_Terminal\" tem um comprimento incorreto no XML- String recebido"
    },
    {
        "key": "XML0011",
        "value": "O elemento \"Ds_Terminal\" não tem um formato numérico no XML- String recebido"
    },
    {
        "key": "XML0012",
        "value": "Não há elemento \"Ds_Order\" no XML- String recebido"
    },
    {
        "key": "XML0013",
        "value": "O elemento \"Ds_Order\" está vazio no XML- String recebido"
    },
    {
        "key": "XML0014",
        "value": "O elemento \"Ds_Order\" tem um comprimento incorreto no XML- String recebido"
    },
    {
        "key": "XML0015",
        "value": "O elemento \"Ds_Order\" não tem suas 4 primeiras posições numéricas no XML- String recebido"
    },
    {
        "key": "XML0016",
        "value": "O elemento \"Ds_TransactionType\" não existe no XML- String recebido"
    },
    {
        "key": "XML0017",
        "value": "O elemento \"Ds_TransactionType\" está vazio no XML- String recebido"
    },
    {
        "key": "XML0018",
        "value": "O elemento \"Ds_TransactionType\" tem um comprimento incorreto no XML- String recebido"
    },
    {
        "key": "XML0019",
        "value": "O elemento \"Ds_TransactionType\" não tem um formato numérico no XML- String recebido"
    },
    {
        "key": "XML0020",
        "value": "O elemento \"Ds_TransactionType\" tem um valor desconhecido ou inválido em uma mensagem de transação"
    },
    {
        "key": "XML0021",
        "value": "Não há elemento \"Assinatura\" na string XML recebida"
    },
    {
        "key": "XML0022",
        "value": "O elemento \"Assinatura\" está vazio na string XML recebida"
    },
    {
        "key": "XML0023",
        "value": "A assinatura não está correta"
    },
    {
        "key": "XML0024",
        "value": "Não há operações para os dados solicitados"
    },
    {
        "key": "XML0025",
        "value": "Resposta XML malformada"
    },
    {
        "key": "XML0026",
        "value": "O elemento \"Ds_start_date\" não existe no XML- String recebido"
    },
    {
        "key": "XML0027",
        "value": "O elemento \"Ds_fecha_fin\" não existe no XML- String recebido"
    },
    {
        "key": "XML0028",
        "value": "O terminal comercial está desativado"
    },
    {
        "key": "XML0029",
        "value": "O elemento \"SignatureVersion\" está vazio no XML- String recebido"
    },
    {
        "key": "XML0030",
        "value": "O elemento \"SignatureVersion\" vem com um valor incorreto no XML- String recebido"
    },
    {
        "key": "XML0031",
        "value": "O elemento \"Input\" vem com um valor ilegal no XML- String recebido"
    },
    {
        "key": "XML0032",
        "value": "O elemento \"Authorized\" vem com um valor ilegal no XML- String recebido"
    },
    {
        "key": "XML0033",
        "value": "O elemento \"ImporteMayor\" vem com um valor ilegal no XML- String recebido"
    },
    {
        "key": "XML0034",
        "value": "O elemento \"AmountMinor\" vem com um valor ilegal no XML- String recebido"
    },
    {
        "key": "XML0035",
        "value": "O elemento \"Authenticated\" vem com um valor ilegal no XML- String recebido"
    },
    {
        "key": "XML0036",
        "value": "O elemento \"CC\" vem com um valor ilegal na string XML recebida"
    },
    {
        "key": "XML0037",
        "value": "O elemento \"Paymethod\" vem com um valor ilegal no XML- String recebido"
    },
    {
        "key": "XML0038",
        "value": "Erro, o elemento \"Ds_fecha_inicio\" tem mais de um ano"
    },
    {
        "key": "XML0039",
        "value": "Erro, a diferença entre \"Ds_fecha_inicio\" e \"Ds_fecha_fin\" excede 15 dias"
    },
    {
        "key": "XML0040",
        "value": "O erro \"Ds_fecha_fin\" é anterior a \"Ds_fecha_inicio\""
    },
    {
        "key": "XML0041",
        "value": "O comerciante não pode usar o erro de consulta SOAP"
    }
]