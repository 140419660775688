import { LoadingButton } from "@mui/lab";
import { Autocomplete, Button, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material"
import TooltipUI from "components/@ui/Tooltip";
import { CEPMaskCustom, CNPJMaskCustom, CPFMaskCustom, CellphoneMaskCustom } from "components/@ui/masked";
import Breadcrumb from "components/breadcrumbs"
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import RemoteServices from "service";
import { colors } from "theme";
import estadosCidades from 'assets/json/estados_cidades.json'
import { schemaSalesCounter, schemaSalesCounterCreate } from "@utils/validator";
import Toast from "components/@ui/toast";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { awaitTime } from "utils/awaitTime";
import { WhatsApp } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import ButtonUI from "components/@ui/button";
import { dataBR } from "@utils/format";

const NewFilial = () => {

    const location = useLocation();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [awatingData, setAwatingData] = useState(true);
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);

    const [counterIdentification, setCounterIdentification] = useState('');
    const [tradeName, setTradeName] = useState('');
    const [statusTradeName, setStatusTradeName] = useState('');

    const [responsibleName, setResponsibleName] = useState('');
    const [statusResponsibleName, setStatusResponsibleName] = useState('');

    const [CPF, setCPF] = useState('');
    const [statusCPF, setStatusCPF] = useState('');

    const [CNPJ, setCNPJ] = useState('');
    const [statusCNPJ, setStatusCNPJ] = useState('');

    const [cellphone, setCellphone] = useState('');
    const [statusCellphone, setStatusCellphone] = useState('');

    const [email, setEmail] = useState('');
    const [statusEmail, setStatusEmail] = useState('');

    const [password, setPassword] = useState('');
    const [statusPassword, setStatusPassword] = useState('');

    const [address, setAddress] = useState('');
    const [statusAddress, setStatusAddress] = useState('');

    const [addressNumber, setAddressNumber] = useState('');
    const [statusAddressNumber, setStatusAddressNumber] = useState('');

    const [district, setDistrict] = useState('');
    const [statusDistrict, setStatusDistrict] = useState('');

    const [addressComplement, setAddressComplement] = useState('');

    const [CEP, setCEP] = useState('');
    const [statusCEP, setStatusCEP] = useState('');

    const [state, setState] = useState({ nome: '', sigla: '' });
    const [statusState, setStatusState] = useState('');

    const [city, setCity] = useState('');
    const [statusCity, setStatusCity] = useState('');

    const [cities, setCities] = useState<any[]>([]);
    const [showForm, setShowForm] = useState(false)
    const [loadingCPF, setLoadingCPF] = useState(false)
    const [loadingCNPJ, setLoadingCNPJ] = useState(false)
    const [disabledByCEP, setDisabledByCEP] = useState(false)
    const [disableTradeName, setDisableTradeName] = useState(false)
    const [requestBirthday, setRequestBirthday] = useState(false)
    const [birthday, setBirthday] = useState<Moment | null>(null);
    const dateLastEighteenYears = moment()
    const dateLastOneHundredAndTenYears = moment()
        .subtract(110, 'years')
        .format(moment.HTML5_FMT.DATE);

    const handleStates = (event: any, newValue: any) => {
        const stateInitials = newValue.sigla || newValue;

        if (statusState) {
            setStatusState('');
        }
        if (city) {
            setCity('');
        }

        estadosCidades.forEach((element: { nome: string, sigla: string, cidades: any[] }) => {
            if (element.sigla === stateInitials) {
                setCities(element.cidades);
                setState({
                    nome: element.nome,
                    sigla: element.sigla,
                });
            }
        });
    };


    useEffect(() => {

    }, [])


    const submitForm = async () => {
        setLoading(true);
        schemaSalesCounterCreate
            .validate(
                {
                    tradeName,
                    responsibleName,
                    CPF,
                    CNPJ: CNPJ || null,
                    cellphone,
                    email,
                    password,
                    address,
                    addressNumber,
                    district,
                    CEP,
                    city,
                    state,
                },
                { abortEarly: false },
            )
            .then(() => {
                (async () => {
                    const data = {
                        trade_name: tradeName,
                        responsible_name: responsibleName,
                        responsible_cpf: CPF,
                        cellphone,
                        email,
                        password,
                        address,
                        address_number: addressNumber,
                        district,
                        city,
                        state: state.sigla,
                        postal_code: CEP,
                        cnpj: undefined,
                        address_complement: undefined
                    };

                    if (CNPJ) {
                        data.cnpj = CNPJ as unknown as undefined;
                    }

                    if (addressComplement) {
                        data.address_complement = addressComplement as unknown as undefined;;
                    }

                    RemoteServices.filiais.newFilial({
                        data
                    })
                        .then(({ response, status }) => {
                            if (status > 201) {
                                Toast({
                                    type: 'error',
                                }, response?.message ?? response?.errors[0] ?? 'Não foi possível Criar nova filial. Verifique os dados e tente novamente.!');
                                return
                            }
                            Toast({
                                type: 'success',
                            }, response?.message ?? 'Filial criada com successo!');
                            navigate('/app/filiais')
                        })
                        .catch((error) => {
                            Toast({
                                type: 'warning',
                            }, error?.errors[0] ?? error?.message ?? 'Não foi possível Criar nova filial. Verifique os dados e tente novamente..');
                        })
                        .finally(() => setLoading(false))
                })();
            })
            .catch((error) => {
                setLoading(false);
                handleValidationError(error);
                Toast(
                    {
                        type: 'warning',
                    }, 'Não foi possível Criar nova filial. Verifique os dados e tente novamente.',
                );
            });
    };


    const handleValidationError = (err: any) => {
        err.inner.forEach((element: any) => {
            switch (element.path) {
                case 'tradeName':
                    setStatusTradeName(element.message);
                    break;
                case 'responsibleName':
                    setStatusResponsibleName(element.message);
                    break;
                case 'CPF':
                    setStatusCPF(element.message);
                    break;
                case 'CNPJ':
                    setStatusCNPJ(element.message);
                    break;
                case 'cellphone':
                    setStatusCellphone(element.message);
                    break;
                case 'email':
                    setStatusEmail(element.message);
                    break;
                case 'password':
                    setStatusPassword(element.message);
                    break;
                case 'address':
                    setStatusAddress(element.message);
                    break;
                case 'addressNumber':
                    setStatusAddressNumber(element.message);
                    break;
                case 'district':
                    setStatusDistrict(element.message);
                    break;
                case 'CEP':
                    setStatusCEP(element.message);
                    break;
                case 'city':
                    setStatusCity(element.message);
                    break;
                case 'state.sigla':
                    setStatusState(element.message);
                    break;
                default:
                    break;
            }
        });
    };

    const consultCPF = async (cpf: string) => {
        setLoadingCPF(true)
        await RemoteServices.proposals.ConsultLocalizeWithoutBirthday({
            cpf
        }).then((res) => {
            if (res.response.result) {
                let result = res.response.result
                setResponsibleName(result.name)
                setShowForm(true)
            } else {
                if (["CPF não localizado.", "Não conseguimos localizar este documento em nossa base de dados.", "Ops, não encontramos informações para o documento que você procurou!"].includes(res.response.message.resposta)) {
                    setRequestBirthday(true)
                } else {
                    setStatusCPF(res.response.message.resposta)
                    setShowForm(false)
                }
            }
        }).finally(() => setLoadingCPF(false))
    }

    const consultCPFWithBirthday = async () => {
        setLoadingCPF(true)
        await RemoteServices.proposals.ConsultLocalize({
            cpf: CPF.replace(/\D/g, ''),
            birthday: dataBR(new Date(birthday as unknown as string))
        }).then((res) => {
            if (res.response.result && res.response.result.error === false) {
                let result = res.response.result
                setResponsibleName(result.name)
                setShowForm(true)
                setRequestBirthday(false)
                setStatusCPF("")
            } else {
                setStatusCPF(res.response.message?.resposta ?? res.response?.message ?? res.response?.result?.message)
                setShowForm(false)
            }
        }).finally(() => setLoadingCPF(false))
    }

    const consultCNPJ = async (cnpj: string) => {
        setLoadingCNPJ(true)
        await RemoteServices.proposals.ConsultLocalizeWithoutBirthday({
            cnpj
        }).then((res) => {
            if (!res.response.result?.error) {
                let result = res.response.result
                let value = result?.razao ?? result?.fantasia
                if (value) {
                    setTradeName(value)
                    setDisableTradeName(true)
                } else {
                    setDisableTradeName(false)
                }
                if (result?.address?.cep) {
                    setCEP(result?.address?.cep)
                    getCep(result?.address?.cep)
                }
            } else {
                setDisableTradeName(false)
                setTradeName("")
            }
        }).catch(() => {
            setDisableTradeName(false)
            setTradeName("")
        }).finally(() => setLoadingCNPJ(false))
    }

    function getCep(cep: string) {
        const formatCep = cep.replace(/[^\d]/g, "")
        RemoteServices.external.GetCep({
            formatCep
        }).then(async ({ response }) => {
            if (!response?.erro) {
                const state = estadosCidades.find((item) => item.sigla === response?.uf)

                if (state && response?.localidade) {
                    setState({ nome: state?.nome ?? '', sigla: state?.sigla ?? '' })
                    setCity(response?.localidade)
                    setDisabledByCEP(true)
                    setDistrict(response?.bairro)
                    setAddress(response?.logradouro)
                }
                if (statusState) setStatusState('')
                if (statusCity) setStatusCity('')
                if (statusDistrict) setStatusDistrict('')
                if (statusAddress) setStatusAddress('')
            } else {
                setCity('')
                setState({ nome: '', sigla: '' })
                setDisabledByCEP(false)
            }
        })
    }

    return (
        <Grid p={2}>
            <Breadcrumb />
            <Grid px={1} py={2} mt={2}>
                <Typography
                    textTransform={"uppercase"}
                    color={colors.black}
                    variant="h5"
                    fontWeight={"bold"}>Nova Filial - Cadastro</Typography>
                <Grid container xs={12} mt={4} gap={2} maxWidth={1280}>
                    <Grid container xs={12} gap={2}>
                        <Grid container md={5} lg={5} xs={12} gap={2}>
                            <FormControl fullWidth error={!!statusCPF} variant="outlined">
                                <InputLabel htmlFor="counterCPF">
                                    CPF do responsável
                                </InputLabel>
                                <OutlinedInput
                                    disabled={showForm || requestBirthday}
                                    id="counterCPF"
                                    label="CPF do responsável"
                                    inputComponent={CPFMaskCustom}
                                    value={CPF}
                                    onChange={(event) => {
                                        if (statusCPF) {
                                            setStatusCPF('');
                                        }
                                        setCPF(event.target.value);
                                        if (event.target.value.length === 14) {
                                            consultCPF(event.target.value)
                                        }
                                    }}
                                    aria-describedby="component-error-cpf"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {loadingCPF && <CircularProgress size={24} />}
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="component-error-cpf">
                                    {statusCPF}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        {requestBirthday &&
                            <Grid container md={12} lg={12} xs={12} display={"flex"} gap={2}>
                                <FormControl>
                                    <DatePicker
                                        label="Data de nascimento"
                                        value={birthday ? moment(birthday) : null}
                                        sx={{ maxWidth: 250, width: '100%' }}
                                        maxDate={dateLastEighteenYears}
                                        format='DD/MM/YYYY'
                                        minDate={moment(dateLastOneHundredAndTenYears)}
                                        onChange={(date) => {
                                            setBirthday(date);
                                        }}
                                    />
                                    <FormHelperText sx={{
                                        width: 240
                                    }}>Não foi possível encontrar o CPF informado. Por favor, informe também a data de nascimento para realizar uma nova busca.</FormHelperText>
                                </FormControl>
                                <ButtonUI sx={{
                                    height: 54
                                }} variant="contained" loading={loadingCPF} disabled={loadingCPF || !birthday}
                                    onClick={() => consultCPFWithBirthday()}
                                >Buscar</ButtonUI>
                            </Grid>}
                        <Grid container md={5} lg={5} xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                label="Nome do responsável"
                                variant="outlined"
                                value={responsibleName}
                                error={!!statusResponsibleName}
                                helperText={statusResponsibleName}
                                onChange={(event) => {
                                    if (statusResponsibleName) {
                                        setStatusResponsibleName('');
                                    }
                                    setResponsibleName(event.target.value);
                                }} />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} gap={2}>

                        <Grid container md={3.5} lg={3.5} xs={12}>
                            <FormControl fullWidth error={!!statusCNPJ} variant="outlined">
                                <InputLabel htmlFor="CNPJ">
                                    CNPJ
                                </InputLabel>
                                <OutlinedInput
                                    id="CNPJ"
                                    fullWidth
                                    disabled={!showForm || requestBirthday}
                                    label="CNPJ"
                                    value={CNPJ}
                                    inputComponent={CNPJMaskCustom}
                                    error={!!statusCNPJ}
                                    onChange={(event) => {
                                        if (statusCNPJ) {
                                            setStatusCNPJ('');
                                        }
                                        setCNPJ(event.target.value);
                                        console.log(event.target.value.length)
                                        if (event.target.value.length === 18) {
                                            consultCNPJ(event.target.value)
                                        }
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {loadingCNPJ && <CircularProgress size={24} />}
                                        </InputAdornment>
                                    } />
                                <FormHelperText id="component-error-cpf">
                                    {statusCNPJ !== "" ? statusCNPJ : "(Não obrigatório)"}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container md={3.5} lg={3.5} xs={12}>
                            <TextField
                                fullWidth
                                disabled={!showForm || requestBirthday || disableTradeName}
                                label="Nome fantasia"
                                variant="outlined"
                                value={tradeName}
                                error={!!statusTradeName}
                                helperText={statusTradeName !== "" ? statusTradeName : "(Não obrigatório)"}
                                onChange={(event) => {
                                    if (statusTradeName) {
                                        setStatusTradeName('');
                                    }
                                    setTradeName(event.target.value);
                                }} />
                        </Grid>
                        <Grid container md={3} lg={3} xs={12}>
                            <FormControl
                                fullWidth
                                error={!!statusCellphone}
                                variant="outlined"
                            >
                                <InputLabel htmlFor="operatorCellphone">
                                    Whatsapp
                                </InputLabel>
                                <OutlinedInput
                                    disabled={!showForm || requestBirthday}
                                    id="operatorCellphone"
                                    label="Whatsapp"
                                    inputComponent={CellphoneMaskCustom}
                                    value={cellphone}
                                    onChange={(event) => {
                                        if (statusCellphone) {
                                            setStatusCellphone('');
                                        }
                                        setCellphone(event.target.value);
                                    }}
                                    aria-describedby="component-error-cellphone"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <WhatsApp color="success" />
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="component-error-cellphone">
                                    {statusCellphone}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} gap={2}>
                        <Grid container xs={12} md={3} lg={3}>
                            <FormControl fullWidth variant="outlined" error={!!statusCEP}>
                                <InputLabel htmlFor="cep">CEP</InputLabel>
                                <OutlinedInput
                                    id="cep"
                                    label="CEP"
                                    inputComponent={CEPMaskCustom}
                                    disabled={!showForm || requestBirthday}
                                    value={CEP}
                                    onChange={(event) => {
                                        if (statusCEP) {
                                            setStatusCEP('');
                                        }
                                        setCEP(event.target.value);
                                        if (event.target.value.replace(/[^\d]/g, "").length === 8) {
                                            getCep(event.target.value)
                                        }
                                    }}
                                    aria-describedby="component-error-cep"
                                />
                                <FormHelperText>{statusCEP}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container md={1} lg={1} xs={12}>
                            <Autocomplete
                                fullWidth
                                // @ts-ignore
                                value={state}
                                disabled={!showForm || disabledByCEP || requestBirthday}
                                options={estadosCidades}
                                noOptionsText="Estado não encontrado"
                                onChange={handleStates}
                                disableClearable
                                getOptionLabel={(option: { nome: string; sigla: string; cidades: string[]; }) => option.nome || ""}
                                renderInput={(params) => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label="Estado"
                                        variant="outlined"
                                        error={!!statusState}
                                        helperText={statusState}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid container xs={12} md={3} lg={3}>
                            <Autocomplete
                                fullWidth
                                disabled={!showForm || disabledByCEP || requestBirthday}
                                options={cities}
                                noOptionsText="Cidade não encontrada"
                                value={city}
                                onChange={(event, newValue) => {
                                    if (statusCity) {
                                        setStatusCity('');
                                    }
                                    setCity(newValue);
                                }}
                                autoHighlight
                                disableClearable
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label="Cidade"
                                        variant="outlined"
                                        error={!!statusCity}
                                        helperText={statusCity}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid container md={2.8} lg={2.8} xs={12}>
                            <TextField
                                fullWidth
                                disabled={!showForm || requestBirthday}
                                label="Bairro"
                                variant="outlined"
                                value={district}
                                error={!!statusDistrict}
                                helperText={statusDistrict}
                                onChange={(event) => {
                                    if (statusDistrict) {
                                        setStatusDistrict('');
                                    }
                                    setDistrict(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} gap={2}>
                        <Grid container xs={12} md={4} lg={4}>
                            <TextField
                                disabled={!showForm || requestBirthday}
                                fullWidth
                                label="Logradouro"
                                variant="outlined"
                                value={address}
                                error={!!statusAddress}
                                helperText={statusAddress}
                                onChange={(event) => {
                                    if (statusAddress) {
                                        setStatusAddress('');
                                    }
                                    setAddress(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid container xs={12} md={3} lg={3}>
                            <TextField
                                disabled={!showForm || requestBirthday}
                                fullWidth
                                label="Número"
                                variant="outlined"
                                value={addressNumber}
                                error={!!statusAddressNumber}
                                helperText={statusAddressNumber}
                                onChange={(event) => {
                                    if (statusAddressNumber) {
                                        setStatusAddressNumber('');
                                    }
                                    setAddressNumber(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid container md={2.9} lg={2.9} xs={12}>
                            <TextField
                                disabled={!showForm || requestBirthday}
                                fullWidth
                                label="Complemento"
                                variant="outlined"
                                value={addressComplement}
                                helperText={"(Não obrigatório)"}
                                onChange={(event) => {
                                    setAddressComplement(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="h5" color={colors.black}>DADOS PARA ACESSO</Typography>
                    <Grid container xs={12} gap={2}>
                        <Grid item md={5} lg={5} xs={12}>
                            <TextField
                                disabled={!showForm || requestBirthday}
                                fullWidth
                                type="email"
                                label="E-mail"
                                variant="outlined"
                                value={email}
                                error={!!statusEmail}
                                helperText={statusEmail}
                                onChange={(event) => {
                                    if (statusEmail) {
                                        setStatusEmail('');
                                    }
                                    setEmail(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item md={5} lg={5} xs={12}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                error={!!statusPassword}
                            >
                                <InputLabel htmlFor="password">Senha</InputLabel>
                                <OutlinedInput
                                    disabled={!showForm}
                                    id="password"
                                    autoComplete="off"
                                    label="Senha"
                                    value={password}
                                    onChange={(e) => {
                                        if (statusPassword) {
                                            setStatusPassword('');
                                        }
                                        setPassword(e.target.value);
                                    }}
                                    type={passwordIsVisible ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    setPasswordIsVisible(!passwordIsVisible);
                                                }}
                                                edge="end"
                                            >
                                                {passwordIsVisible ? (
                                                    <FiEye />
                                                ) : (
                                                    <FiEyeOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>{statusPassword}</FormHelperText>
                            </FormControl>

                        </Grid>
                    </Grid>
                    <Grid xs={12} md={10.15} lg={10.15} mt={2} container justifyContent="flex-end">
                        <LoadingButton
                            loading={loading}
                            disabled={loading || !showForm || requestBirthday}
                            onClick={submitForm}
                            variant="contained"
                            disableElevation
                            sx={{ p: 2, maxWidth: 240, width: '100%', justifySelf: "flex-end" }}>
                            Cadastrar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NewFilial;